import { type LoaderFunctionArgs, type MetaFunction } from 'react-router'
import { useTranslation } from 'react-i18next'
import { LoginForm } from '~/components/auth-form/login-form'
import AuthContainer from '~/components/container/auth-container'
import { Icon } from '~/components/icon/icon'
import { NavLink } from '~/components/internal-nav-link/Internal-nav-link'
import { SetMeta } from '~/utils/seo/seo'
import { requireAnonymous } from '~/utils/auth/auth.server'
import i18next from '~/configs/i18next.server'
import { getServerLang } from '~/utils/i18n.utils'
export { action } from './login.server'

export async function loader({ request }: LoaderFunctionArgs) {
	await requireAnonymous(request)
	const lng = getServerLang(request)
	const t = await i18next.getFixedT(lng.lngShort, 'common')
	return {
		title: t('login_title'),
		description: t('login_title_meta_description'),
	}
}

export default function LoginPage() {
	const { t } = useTranslation()
	return (
		<AuthContainer>
			<NavLink
				to="/"
				className="fixed left-5 top-5 hidden text-4xl md:block"
				aria-label={t('action_go_to_homepage')}
			>
				<Icon name="arrow-left" />
			</NavLink>
			<LoginForm />
		</AuthContainer>
	)
}

export const meta: MetaFunction<typeof loader> = ({ location, data }) => {
	return SetMeta({
		title: data?.title,
		description: data?.description,
		location,
	})
}
